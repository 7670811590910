import { Header, Container } from 'semantic-ui-react';
import MetaData from '../MetaData';
import DeploymentLogView from './DeploymentLogs';
import InstanceConfiguration from './InstanceConfiguration';
import InstanceCredentials from './InstanceCredentials';
import { Tabs } from '@okta/odyssey-react-mui';

const InstanceDetails = ({ instance, type, onUpdate, onError }) => {

  return (<Tabs
    ariaLabel="basic tabs example"
    initialValue="config"
    tabs={[
        {
            children: <InstanceConfiguration instance={instance} type={type} onUpdate={onUpdate} />,
            label: 'Config',
            value: 'config'
        },
        ...instance.credentials ? [{
          children: <InstanceCredentials credentials={instance.credentials} />,
          label: 'Credentials',
          value: 'credentials'
        }]:[],
        {
        children:           <Container><MetaData meta_data={instance.meta_data} />
        <Header as='h3'>Deployed Version</Header>
        { instance.deployedVersion } {instance.deployedVersion < instance.latestVersion && <span>(Latest: {instance.latestVersion})</span>}
        <Header as='h3'>Deployment Logs</Header>
        {instance.deploymentHistory?.length > 0 ? <DeploymentLogView deploymentLogs={instance.deploymentHistory} /> : <span>No logs available.</span>} </Container>,
        label: 'Deployment Info',
        value: 'deploymentInfo'
        },
        
    ]}
  />)
}

export default InstanceDetails