import { Image } from 'semantic-ui-react';

const IdpTypeGraphic = ({ type }) => {
    switch (type) {
        case "customer-identity":
            return (
                <Image floated='right'
                    size='mini' src='https://cdn.demo.okta.com/images/okta/CIC/icon_black.png' />
            )
        case "okta-customer-identity":
            return (
                <Image floated='right'
                    size='mini' src='https://cdn.demo.okta.com/images/okta/WIC/icon_black.png' />
            )
        case "workforce-identity":
            return (
                <Image floated='right'
                    size='mini' src='https://cdn.demo.okta.com/images/okta/WIC/icon_black.png' />
            )
        default:
            break;
    }

}
export default IdpTypeGraphic