import { Form } from 'semantic-ui-react';

const SecretSetting = ({name, instanceValue, readOnly=false, onChange}) => {
    return <Form.Input
            id='settings'
            key={name}
            name={'settings.' + name}
            value={instanceValue ? instanceValue : ''}
            onChange={onChange}
            maxLength='1024'
            placeholder={'****** (default)'}
            type='password'
            readOnly={readOnly}
            fluid
            inline
            />
}

export default SecretSetting;