import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: aeonik;
  src: url('https://cdn.demo.okta.com/fonts/aeonik-regular.woff2') format('woff2');
}

  html{
    height: unset;
    min-height: 100vh;
    background-image: linear-gradient(transparent, ${({ theme }) => theme.background} 95%), url('https://cdn.demo.okta.com/images/demo-okta-bg-blue.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${({ theme }) => theme.background};
  }
  body {
    background: transparent;
    color: ${({ theme }) => theme.text};
    font-family: aeonik, sans-serif !important;
    transition: all 0.50s linear;
  }

  a{
    color: ${({ theme }) => theme.brand};
  }
  a:hover{
    color: ${({ theme }) => theme.text};
    cursor: pointer;
  }

  .ui.inverted.dimmer .ui.loader {
    color: ${({ theme }) => theme.text};
  }

  .ui.inverted.dimmer .ui.loader::after {
    border-color: ${({ theme }) => theme.brand} transparent transparent;
  }

  .ui.inverted.dimmer {
      background-color: ${({ theme }) => theme.background};
  }

  a {
    color: ${({ theme }) => theme.brand} !important;
  }

  a:hover {
    color: ${({ theme }) => theme.text} !important;
  }

  .ui.menu {
      background-color: ${({ theme }) => theme.header};
  }

  .ui.menu .item {
    color: ${({ theme }) => theme.contrastText} !important;
  }
 
  .ui.menu .header {
    line-height: reset !important;
    font-size: reset !important;
    color: ${({ theme }) => theme.contrastText} !important;
  }

  .ui.link.menu .item:hover, .ui.menu .dropdown.item:hover, .ui.menu .link.item:hover, .ui.menu a.item:hover{
    color: ${({ theme }) => theme.brand};
  }

  .ui.form input[type="text"],.ui.form input[type="text"]:focus, .ui.selection.dropdown, .ui.dropdown .menu, .ui.menu .ui.dropdown .menu > .item, .ui.menu .ui.dropdown .menu > .active.item, .ui.selection.visible.dropdown > .text:not(.default) {
    background-color: ${({ theme }) => theme.inputBox};
    background: ${({ theme }) => theme.inputBox};
    color: ${({ theme }) => theme.text} !important;
  }

  .ui.list .list > .item a.header, .ui.list > .item a.header {
    color: ${({ theme }) => theme.brand} !important;
  }

  .ui.list .list > .item .description, .ui.list > .item .description {
    color: ${({ theme }) => theme.text};
  }

  .brandText{
    color: ${({ theme }) => theme.brand} !important;
  }

  .brandColor{
    background-color: ${({ theme }) => theme.brand} !important;
    color: ${({ theme }) => theme.contrastText} !important;
  }
  .ui.button {
    font-family: aeonik;
    font-weight: unset;
    letter-spacing: .03em;
    border: 1px solid black;
    color: black;
  }
  .ui.menu {
    font-family: aeonik;
    background-color: ${({ theme }) => theme.brand};
  }
  .ui.card>.content>.header, .ui.cards>.card>.content>.header {
    font-family: aeonik;
  }
  .ui.card > .content {
    background: ${({ theme }) => theme.backgroundLighter} !important;
  }
  .hoverDarkerCard .ui.card > .content:hover {
    background: ${({ theme }) => theme.brandLighter} !important;
  }
  .hoverDarkerCard > .ui.card > .content:hover > .header, .hoverDarkerCard > .ui.card > .content:hover > .meta{
    color: ${({ theme }) => theme.contrastText}
  }
  .segment {
    background-color: ${({ theme }) => theme.backgroundLighter} !important;
  }

  .ui.modal > .header {
    background-color: ${({ theme }) => theme.header};
  }

  .ui.modal > .content {
    text-align: center;
  }

  .errorMsg {
    text-align: center;
  }

  button.branded {
    background-color: ${({ theme }) => theme.brand} !important;
    color: ${({ theme }) => theme.contrastText} !important;
  }

  button.cta {
    background-color: ${({ theme }) => theme.cta} !important;
    color: ${({ theme }) => theme.ctaContrastText} !important;
  }

  button.cta:hover {
    background-color: ${({ theme }) => theme.brand} !important;
    color: ${({ theme }) => theme.contrastText} !important;
  }

  .cta > button {
    background-color: ${({ theme }) => theme.cta} !important;
    color: ${({ theme }) => theme.ctaContrastText} !important;
  }

  .cta > button:hover {
    background-color: ${({ theme }) => theme.brand} !important;
    color: ${({ theme }) => theme.contrastText} !important;
  }

  .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled{
    opacity: 1 !important;
  }

  .ui.form .field.disabled > label, .ui.form .fields.disabled > label {
    opacity: 1 !important;
  }

  .navButton {
    color: ${({ theme }) => theme.brand};
  }

  .card, .ui.message{
    background: ${({ theme }) => theme.backgroundLighter} !important;
  }

  div.ui.icon.message, .ui.cards .ui.card, .ui.container .ui.card, .ui.container .ui.segment {
    border-radius:15px;
  }

  .identicon{
    filter: sepia(100%) hue-rotate(192deg) drop-shadow(2px 1px 1px rgb(0 0 0 / 0.4));
  }
  .ui.modal {
    border-radius: 15px !important;
  }

  .ui.modal > .header{
    background-color: ${({ theme }) => theme.brand};
    color: ${({ theme }) => theme.contrastText} !important;
    font-weight: unset !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  .instanceDetails > .menu {
    margin-top: 10px;
    background-color: ${({ theme }) => theme.backgroundLighter};
    color: ${({ theme }) => theme.brand};
    border-radius: 15px;
  }

  .ui.menu > .item:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  }


  .instanceDetails > .menu > .item {
    color: ${({ theme }) => theme.brand} !important;
  }

  .meta {
    margin-top: 10px !important;
  }

  
  `