import { Form } from "semantic-ui-react"
import { SettingTypeOptions } from "../ComponentSettingsConstants"

const SettingTypeSelector = ({settingType = 'text', disabled=false, onChange}) => {
    return <Form.Dropdown
    name="newSettingType"
    onChange={onChange}
    options={SettingTypeOptions}
    placeholder="type"
    value={settingType}
    disabled={disabled}
    selection
    fluid
    />
}

export default SettingTypeSelector;