import { Container, Header, Loader, Button, Segment, Grid, List, Divider, Image } from 'semantic-ui-react';
import { useNavigate } from "react-router";
import IdpType from './IdpType';
import flagsmith from 'flagsmith';
import StateLabel from './StateLabel';
import { useUserPreferenceContext } from '../UserPreferenceContext';

const OneClickGuide = ({ demo, idp }) => {
    const navigate = useNavigate();
    const userPreference = useUserPreferenceContext();

    function handleOnClick(path) {
        navigate(path)
    }

    return (
        <Container>
            <Segment basic >
                <Grid columns={demo.resource_instances?.length > 0 && demo.app_instances?.length > 0 ? '3' : '2'}>
                    <Grid.Column>
                        <Container>
                            <Header as='h2'>{idp ? (<IdpType idp={idp} />) : (<span>Identity Cloud</span>)}</Header>
                            {idp ? (
                                <Container>
                                    <p>
                                        {idp.type === 'customer-identity' && idp.invite_link ? (
                                            <span>Accept your invitation to be able to launch the <IdpType idp={idp} /></span>) :
                                            (<span>Check your email for the tenant invite.</span>)
                                        }
                                        <br />
                                        <span>Click launch to open the management dashboard.</span>
                                    </p>
                                    {idp.type === 'customer-identity' && idp.invite_link &&
                                        <Button onClick={() => window.open(idp.invite_link)}>
                                            Accept Invitation
                                        </Button>
                                    }
                                    <Button className="cta" onClick={() => window.open(idp.dashboard)} disabled={idp.state !== "active"}>
                                        Launch
                                    </Button>
                                </Container>
                            ) : (<Loader active inline />)}
                        </Container>
                    </Grid.Column>
                    {demo.app_instances.length !== 0 &&
                        <Grid.Column>
                            {
                                [...demo.app_instances].sort((a, b) => a.label.localeCompare(b.label)).map((app) =>
                                    <Grid.Row>
                                        <Container key={app.id + 'heading'}>
                                            <Header as='h2'>
                                                {app.label}
                                                {app.logo && <Image floated="right" size='mini' src={app.logo} />}
                                                <span className="ComponentMetaRow">
                                                    {app.documentationLink && <a target="_blank" rel="noreferrer" href={app.documentationLink}>Documentation</a>}
                                                    {app.video && <a target="_blank" rel="noreferrer" href={app.video}>Video</a>}
                                                    {app.supportLink && <a target="_blank" rel="noreferrer" href={app.supportLink}>Support</a>}
                                                </span>
                                                {app.state !== 'active' && <Header sub><StateLabel state={app.state} /></Header>}
                                            </Header>
                                            <Container key={app.id + 'links'}>
                                                <p>
                                                    Start your demonstration app, by clicking launch below.
                                                    <br />
                                                    You can customize your application by clicking manage.
                                                </p>
                                                <Button className="cta" onClick={() => window.open(userPreference.rewriteLaunchUrl(app.link))}>
                                                    Launch
                                                </Button>
                                                <Button className="" onClick={() => handleOnClick(`/demo/${demo.name}/apps/${app.applicationId}`)}>
                                                    Manage
                                                </Button>
                                            </Container>
                                            <Divider hidden />
                                        </Container>
                                    </Grid.Row>
                                )
                            }
                        </Grid.Column>
                    }
                    {demo.resource_instances.length !== 0 &&
                        <Grid.Column>
                            {[...demo.resource_instances].sort((a, b) => a.label.localeCompare(b.label)).map((resource) =>
                                <Grid.Row>
                                    <Container key={resource.id + 'heading'}>
                                        <Header as={(resource.settings && Object.keys(resource.settings).length > 0) || resource.launchUrl ? 'h2' : 'h3'}>
                                            {resource.label}
                                            {resource.logo && <Image floated="right" size='mini' src={resource.logo} />}
                                            <span className="ComponentMetaRow">
                                                {resource.documentationLink && <a target="_blank" rel="noreferrer" href={resource.documentationLink}>Documentation</a>}
                                                {resource.video && <a target="_blank" rel="noreferrer" href={resource.video}>Video</a>}
                                                {resource.supportLink && <a target="_blank" rel="noreferrer" href={resource.supportLink}>Support</a>}
                                            </span>
                                            {resource.state !== 'active' && <Header sub><StateLabel state={resource.state} /></Header>}
                                        </Header>
                                        <Container key={resource.id + 'links'}>
                                            <p>
                                                The resource <b>{resource.label}</b> is applied to this environment.
                                                <br />
                                                {((resource.settings && Object.keys(resource.settings).length > 0) || resource.launchUrl) && <span>You can customize this resource by clicking manage.</span>}
                                            </p>
                                            {resource.launchUrl &&
                                                <Button className="cta" onClick={() => window.open(userPreference.rewriteLaunchUrl(resource.launchUrl))}>
                                                    Launch
                                                </Button>
                                            }
                                            {((resource.settings && Object.keys(resource.settings).length > 0) || resource.launchUrl) ? (
                                                <Button className="" onClick={() => handleOnClick(`/demo/${demo.name}/resources/${resource.resourceId}`)}>
                                                    Manage
                                                </Button>
                                            ) : (
                                                <a href='#detail' onClick={() => handleOnClick(`/demo/${demo.name}/resources/${resource.resourceId}`)}>More info</a>
                                            )
                                            }
                                        </Container>
                                        <Divider hidden />
                                    </Container>
                                </Grid.Row>
                            )
                            }
                        </Grid.Column>
                    }
                </Grid>
            </Segment>

            <Segment basic tertiary>
                <Grid columns={2}>
                    <Grid.Column>

                        <List>
                            {flagsmith.getTrait("connection") === "employee" &&
                                <List.Item key={'slack'}>
                                    <List.Icon name='slack' />
                                    <List.Content>Get support in Slack <a target='_blank' rel='noreferrer' href="https://okta.slack.com/archives/C051GGRJW20">#hypercare-one-click-demo</a></List.Content>
                                </List.Item>
                            }
                            <List.Item key={'feedback'}>
                                <List.Icon name='comment' />
                                <List.Content><a target='_blank' rel='noreferrer' href="https://forms.gle/pC8GBDE6UBJgpgjY8">Send feedback</a></List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        Don't need this guide any more?<br /><a href='#clearguide' onClick={() => flagsmith.setTrait("hide_one_click_guide", true)}>Hide</a>
                    </Grid.Column>
                </Grid>

            </Segment>

        </Container >
    )
}
export default OneClickGuide