import React from "react";
import { Button, Message } from 'semantic-ui-react';
import { useFlags } from 'flagsmith/react';
import './Enablement.css'

const Enablement = () => {
    const flags = useFlags(['enablement_prompt']);
    const show = flags.enablement_prompt.enabled
    const value = JSON.parse(flags.enablement_prompt.value)

    if(show){
            return (
                <div className="appComponent">  
                <Message id="enablementComponent">
                    <Message.Content>
                        <Message.Header>{value.heading}</Message.Header>
                        <p>{value.subMessage}</p>
                        <div className="linkContainer">
                        {value.ctaAction && value.cta &&
                            <a href={value.ctaAction} target="_blank" rel="noreferrer"><Button className="primaryAction enablementAction">{value.cta}</Button></a>
                        }
                        {value.ctaSecondaryAction && value.ctaSecondary &&
                            <a href={value.ctaSecondaryAction} target="_blank" rel="noreferrer"><Button className="secondaryAction enablementAction">{value.ctaSecondary}</Button></a>
                        }
                        </div>
                    </Message.Content>
                </Message>           
            </div>
        );
    }
};
export default Enablement;
