import { Icon } from 'semantic-ui-react';

const CategoryLabel = ({category}) => {
    switch (category) {
        case "private":
            return (
                <span>
                    <Icon color='grey' name='user' /> Private
                </span>
            )
        case "community":
            return (
                <span>
                    <Icon color='blue' name='users' /> Community
                </span>
            )
        case "managed":
            return (
                <span>
                    <Icon color='red' name='users' /> Managed
                </span>
            )
        default:
            break;
    }

}
export default CategoryLabel