import axios from 'axios'
import Config from "../Config";

export async function createIDP(accessToken, name, idpType, variant = 'production') {
  var data = JSON.stringify({
    "name": name,
    "type": idpType,
    variant
  });

  var config = {
    method: 'post',
    url: Config.resourceServer.demoAPI + '/idp',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}

export async function getIDP(accessToken, idpId) {
  return axios.get(`${Config.resourceServer.demoAPI}/idp/${idpId}`,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  )
}

export async function createDemonstration(accessToken, name, type, idpId, source = "builder", label) {
  var data = JSON.stringify({
    "name": name,
    label,
    "type": type,
    "idp_id": idpId
  });

  var config = {
    method: 'post',
    url: Config.resourceServer.demoAPI + '/demonstration',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'x-creation-source': source
    },
    data: data
  };

  return axios(config)
}

export async function linkDemonstrationToOpp(accessToken, demoName, opportunityId) {
  var data = JSON.stringify({
    "opportunity_id": opportunityId
  });

  var config = {
    method: 'patch',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}

export async function setDemonstrationLabel(accessToken, demoName, label) {
  var data = JSON.stringify({
    "label": label
  });

  var config = {
    method: 'patch',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}

export async function setDemonstrationToOpportunity(accessToken, demoName) {
  var data = JSON.stringify({
    "type": 'opportunity'
  });

  var config = {
    method: 'patch',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}

export async function getDemonstration(accessToken, name) {
  return axios.get(Config.resourceServer.demoAPI + "/demonstration/" + name,
    {
      headers: { 'Authorization': `Bearer ${accessToken}` }
    })
}

export async function listApplications(accessToken, category, idpType) {
  return axios.get(Config.resourceServer.demoAPI + "/applications",
    {
      params: { category: category, supports_idp_type: idpType },
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  )
}

export async function getApplicationInstance(accessToken, demoName, appid) {
  var config = {
    method: 'get',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName + "/apps/" + appid,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return axios(config)
}

export async function attachAppToDemonstration(accessToken, demoName, appid, label, settings) {
  var data = JSON.stringify({
    "application_id": appid,
    "label": label,
    settings: settings
  });

  var config = {
    method: 'post',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName + "/apps",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}


export async function detachAppFromDemonstration(accessToken, demoName, appId) {
  var config = {
    method: 'delete',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName + "/apps/"+appId,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return axios(config)
}

export async function updateApplicationInstance(accessToken, demoName, appId, instanceLabel, instanceSettings) {
  var data = {
    "label": instanceLabel,
    "settings": instanceSettings
  }
  var config = {
    method: 'put',
    url: `${Config.resourceServer.demoAPI}/demonstration/${demoName}/apps/${appId}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: JSON.stringify(data)
  };

  return axios(config)
}

export async function createApplication(accessToken, name, baseAddress, discoveryMechanism, description, hooks, settings, dependencies) {
  var data = JSON.stringify({
    "name": name,
    "baseAddress": baseAddress,
    "multiTenancy": discoveryMechanism,
    "description": description,
    "hooks": hooks,
    "settings": settings,
    "dependsOn": dependencies
  });

  var config = {
    method: 'post',
    url: `${Config.resourceServer.demoAPI}/applications`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(config)
}

export async function getApplication(accessToken, appId) {
  return axios.get(
    `${Config.resourceServer.demoAPI}/applications/${appId}`,
    { headers: { 'Authorization': `Bearer ${accessToken}` } }
  );
}

export async function updateApplication(accessToken, appId, name, baseAddress, multiTenancy, description, hooks, settings, dependencies, documentationLink, supportLink, videoLink, logoLink, tags) {
  var data = JSON.stringify({
    "name": name,
    "baseAddress": baseAddress,
    "multiTenancy": multiTenancy,
    "description": description,
    "hooks": hooks,
    "settings": settings,
    "dependsOn": dependencies,
    "documentationLink": documentationLink,
    "supportLink": supportLink,
    "logo": logoLink,
    "video": videoLink,
    "tags": tags
  })

  var config = {
    method: 'put',
    url: `${Config.resourceServer.demoAPI}/applications/${appId}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}

export async function deleteApplication(accessToken, appId) {
  return axios.delete(
    `${Config.resourceServer.demoAPI}/applications/${appId}`,
    { headers: { 'Authorization': `Bearer ${accessToken}` } }
  );
}

export async function listResources(accessToken, category, idpType) {
  return axios.get(Config.resourceServer.demoAPI + "/resources",
    {
      params: { category: category, supports_idp_type: idpType },
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  )
}

export async function createResource(accessToken, name, description, hooks, settings, dependencies) {
  var data = JSON.stringify({
    "name": name,
    "description": description,
    "hooks": hooks,
    "settings": settings,
    "dependsOn": dependencies
  });

  var config = {
    method: 'post',
    url: Config.resourceServer.demoAPI + '/resources',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}

export async function updateResource(accessToken, resourceId, name, description, hooks, settings, dependencies, documentationLink, supportLink, videoLink, logoLink, tags) {
  var data = JSON.stringify({
    "name": name,
    "description": description,
    "hooks": hooks,
    "settings": settings,
    "dependsOn": dependencies,
    "documentationLink": documentationLink,
    "supportLink": supportLink,
    "video": videoLink,
    "logo": logoLink,
    "tags": tags,
  })

  var config = {
    method: 'put',
    url: Config.resourceServer.demoAPI + '/resources/' + resourceId,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}

export async function attachResourceToDemonstration(accessToken, demoName, resourceId, label, settings) {
  var data = JSON.stringify({
    resourceId: resourceId,
    label: label,
    settings: settings
  });

  var config = {
    method: 'post',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName + "/resources",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  return axios(config)
}

export async function detachResourceFromDemonstration(accessToken, demoName, resourceId) {
  var config = {
    method: 'delete',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName + "/resources/"+resourceId,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return axios(config)
}

export async function deleteResource(accessToken, resourceId) {
  return axios.delete(Config.resourceServer.demoAPI + '/resources/' + resourceId,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
}

export async function addCollaborator(accessToken, componentRoute, user) {
  var config = {
    method: 'patch',
    url: `${Config.resourceServer.demoAPI}/${componentRoute}/share`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: [user]
  };

  return axios(config)
}

export async function getResourceInstance(accessToken, demoName, resourceId) {
  var config = {
    method: 'get',
    url: Config.resourceServer.demoAPI + '/demonstration/' + demoName + "/resources/" + resourceId,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return axios(config)
}

export async function removeCollaborator(accessToken, componentRoute, user) {
  var config = {
    method: 'delete',
    url: `${Config.resourceServer.demoAPI}/${componentRoute}/share`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: [user]
  };

  return axios(config)
}