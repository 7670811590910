import { useState } from 'react';
import { Container, Header, Form, Button, Segment, Divider } from 'semantic-ui-react';
import TemplatePicker from '../TemplatePicker';
import { getTemplateSettings } from "../../util/StorytimeTemplates";

const StorytimePrompt = ({ step, demoName, func_complete }) => {

    const [waiting, setWaiting] = useState(false)
    const [settings, setSettings] = useState()

    async function submit() {
        setWaiting(true)
        var action = step
        action.settings = settings
        func_complete(step)
    }

    async function skip() {
        setWaiting(true)
        func_complete(step)
    }

    function handleChange(event) {
        setSettings(getTemplateSettings(event.target.value))
    }

    return (
        <Container>
            <Container>
                <Segment basic >
                    <Header>Storytime Preset</Header>
                    <Container>
                        <p>
                        Configure the Storytime demonstration for your usecase by selecting from the presets below to apply cosmetic and functional configuration changes. 
                        </p>
                    </Container>
                    <Divider hidden />
                    <Form>
                        <TemplatePicker allowEdit={true} updateApplication={handleChange}></TemplatePicker>
                        <Button disabled={waiting} className="branded" type='submit' onClick={submit}>Apply</Button>
                        <Button disabled={waiting} className="" onClick={skip}>Skip</Button>
                    </Form>
                </Segment>
            </Container>
        </Container>
    )
}
export default StorytimePrompt