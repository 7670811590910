import React, {
    useState,
    useEffect,
    useCallback
} from "react";
import {
    Container,
    Button,
    Header,
    Image,
    Card,
    Loader, Message
} from 'semantic-ui-react';
import './demos.css'
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'
import Config from "../Config";
import ErrorMessage from "./ErrorMessage";
import StateLabel from "./StateLabel";
import CategoryLabel from "./CategoryLabel";
import { useNavigate } from "react-router-dom";
import { useFlags } from 'flagsmith/react';

const Resources = () => {

    const navigate = useNavigate();
    const [resources, setResources] = useState();
    const [error, setError] = useState(null)
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const flags = useFlags(['resource_limits']);

    const getResources = useCallback(async () => {
        setError()
        if (isAuthenticated) {
            axios.get(Config.resourceServer.demoAPI + "/resources",
                {
                    headers: { Authorization: "Bearer " + await getAccessTokenSilently() },
                    params: { owner: user.email }
                }
            )
                .then((response) => { setResources(response.data) })
                .catch((error) => { setError(error); })
        } else {
            setResources([]);
        }
    }, [getAccessTokenSilently, isAuthenticated, setResources, user.email])

    useEffect(() => {
        if (isAuthenticated) {
            getResources()
        } else {
            setResources([])
        }
    }, [getResources, isAuthenticated, getAccessTokenSilently, setResources])

    function handleOnClick(path) {
        navigate(path)
    }

    return (
        <div className="appComponent">
            <Header className="contentHeader">Your Resources</Header>

            {resources ? (
                <Container>
                    <Button
                        className="createActionButton branded"
                        positive icon='plus' labelPosition='left'
                        content='New Resource'
                        onClick={() => handleOnClick('/resource')}
                        disabled={
                            flags.resource_limits.enabled &&
                            resources.length >= flags.resource_limits.value}
                    />
                    {
                        flags.resource_limits.enabled &&
                        resources.length >= flags.resource_limits.value &&
                        <span>You've reached the maximum number of resources allowed.</span>
                    }
                    {resources.length !== 0 ? (
                        <Card.Group className="hoverDarkerCard">
                            {resources.map((resource) =>
                                <Card key={resource.id} onClick={() => handleOnClick(`/resource/${resource.resource_id}`)}>
                                    <Card.Content>
                                        {resource.logoUri ?
                                            <Image
                                                floated='right'
                                                size='mini'
                                                className='identicon'
                                                src={resource.logoUri} />
                                            :
                                            <Image
                                                floated='right'
                                                size='mini'
                                                className='identicon'
                                                src='https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-integrations-marketplace.svg' />
                                        }
                                        <Card.Header>{resource.name}</Card.Header>
                                        <Card.Meta>
                                            <CategoryLabel category={resource.category} />
                                            <StateLabel state={resource.state} />
                                        </Card.Meta>
                                        <Card.Meta>
                                            {resource.baseAddress}
                                        </Card.Meta>
                                    </Card.Content>
                                </Card>
                            )}
                        </Card.Group>
                    ) : (
                        <Container>
                            <Message>
                                <Message.Header>
                                    <Image
                                        size='mini'
                                        className='identicon'
                                        src='https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-integrations-marketplace.svg'
                                    /> Resources</Message.Header>
                                <Message.Content>
                                    <p>Add your own custom resources to the demo library to easily reuse components between demos or build a true custom experience.</p>
                                    <p>Learn how to get started building custom demo resources with our docs <a target="_blank" rel="noreferrer" href='https://docs.demo.okta.com/'>here</a>.</p>
                                </Message.Content>
                            </Message>
                        </Container>
                    )}
                </Container>
            )
                : (error ?
                    <ErrorMessage error={error} retryAction={getResources} />
                    :
                    <Loader active inline />
                )
            }
        </div>
    );
};
export default Resources;
