import React from "react";
import {
    Container,
    Button,
    Header,
    Image,
    Card,
    Loader,
    Message,
    Icon
} from 'semantic-ui-react';
import './demos.css'
import ErrorMessage from "./ErrorMessage";
import DemoTypeLabel from "./DemoTypeLabel";
import IdpTypeLabel from "./IdpTypeLabel";
import { useNavigate } from "react-router-dom";
import { useDemoContext } from "../DemoContext";

const Demos = () => {

    const { demos, idps, error, refreshContext } = useDemoContext()
    const demoContext = useDemoContext()
    const navigate = useNavigate();

    function handleOnClick(path) {
        navigate(path)
    }

    return (
        <div className="appComponent">
            <Header className="contentHeader">Your Demos<Icon className="refreshControl" name="refresh" onClick={() => refreshContext()} /></Header>
            {error &&
                <ErrorMessage error={error} />}
            {demoContext.loading ? (<Loader active inline />) : (
                <Container>
                    <Button className="createActionButton branded" content='New Demo' icon='plus' labelPosition='left' onClick={() => handleOnClick('/demo')} />
                    {demos.length !== 0 ? (
                        <Card.Group className="hoverDarkerCard">
                            {demos.map((demo) =>
                                <Card key={demo.name} onClick={() => handleOnClick(`/demo/${demo.name}`)}>
                                    <Card.Content>
                                        <Image
                                            className='identicon'
                                            floated='right'
                                            size='mini'
                                            src='https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-tenant.svg'
                                        />
                                        <Card.Header>{demo.label}</Card.Header>
                                        <Card.Meta>
                                            <DemoTypeLabel type={demo.type} />
                                            <IdpTypeLabel idps={idps} idpid={demo.idp_id} />
                                        </Card.Meta>
                                    </Card.Content>
                                </Card>
                            )}
                        </Card.Group>
                    ) : (
                        <Container>
                            <Message>
                                <Message.Header>
                                    <Image
                                        className='identicon'
                                        size='mini'
                                        src='https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-tenant.svg'
                                    /> Demonstrations</Message.Header>
                                <Message.Content>
                                    <p>Demonstrations are self-contained demo environments built around an identity provider: CIC or OIE.</p>
                                    <p>Provision a new identity provider and attach applications or resources to it from the demo library to build a compelling demo.</p>
                                </Message.Content>
                            </Message>
                        </Container>
                    )}
                </Container>
            )}
        </div>
    );
};
export default Demos;
