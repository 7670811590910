import { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';

const InstanceLabel = ({label, onChange}) => {

    const [labelError, setLabelError] = useState()

    function validateForm(event){
        if(event.target.value === ''){
            setLabelError('Please enter an alias for your instance.')
        }
        else{
            setLabelError();
        }
        onChange(event.target.value);
    }

    return <Form.Field
    id='label'
    key='label'
    control={Input}
    label='Label'
    value={label}
    onChange={validateForm}
    error={labelError}
    onBlur={validateForm}
    maxLength='30'
    />
}

export default InstanceLabel;