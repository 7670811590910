import React, { useState } from "react";
import { Form, Button, Table, Container } from "semantic-ui-react";
import './ComponentSettings.css'
import {sortObjectByKeys} from "../util/SortObjectByKeys.js"
import TextSetting from "./settings/TextSetting";
import SecretSetting from "./settings/SecretSetting";
import BooleanSetting from "./settings/BooleanSetting";
import SettingTypeSelector from "./settings/SettingTypeSelector";

const ComponentSettings = ({settings, allowEdit, setSettings}) => {

    const [newSettingType, setNewSettingType] = useState("text")
    const [newSettingName, setNewSettingName] = useState("")
    const [newSettingNameError, setNewSettingNameError] = useState()
    const [newSettingDefault, setNewSettingDefault] = useState("")

    settings = sortObjectByKeys(settings)

    function removeSetting(key){
        var updateSettings = settings
        delete updateSettings[key]
        setSettings({...settings})
    }

    function addNewSetting(){
        setNewSettingNameError()
        if(!newSettingName ||newSettingName === ""){
            setNewSettingNameError("Please enter a name for your setting")
        } else{
            var updateSettings = settings
            updateSettings[newSettingName] = {
                type: newSettingType,
                default: newSettingDefault
            }
            setSettings({...updateSettings})
            setNewSettingType("text")
            setNewSettingDefault("")
            setNewSettingName("")
        }
    }

    function handleChange(event, { name, value }) {
        switch (name) {
            case "newSettingName":
                setNewSettingName(value)
                break
            case "settings.newSettingDefault":
                setNewSettingDefault(value)
                break
            case "newSettingType":
                setNewSettingType(value)
                if(value === "bool"){
                    setNewSettingDefault(true)
                }
                if(value === "text" || value === "secret"){
                    setNewSettingDefault("")
                }
                break
            default:
                var settingKey = name.replace("settings.", "");
                var updateSettings = settings
                if(value.length > 0 || typeof value === 'boolean'){
                    updateSettings[settingKey].default = value
                } else {
                    delete updateSettings[settingKey].default
                }
                setSettings({...settings})
                break;
        }
    }

    return(
        <Container>
            <Table singleLine striped basic='very'>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width={2}>Setting Type</Table.HeaderCell>
                <Table.HeaderCell>Default Value</Table.HeaderCell>
                <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
            </Table.Header>

            {Object.keys(settings).map((key) => 
                <Table.Row>
                    <Table.Cell>{key}</Table.Cell>
                    <Table.Cell>
                        <SettingTypeSelector settingType={settings[key].type} disabled={true} onChange={handleChange} />
                    </Table.Cell>
                    <Table.Cell>
                { settings[key].type === "bool" &&
                    <BooleanSetting name={key} instanceValue={settings[key].default} onChange={handleChange} readOnly={!allowEdit} />
                }    
                { settings[key].type === "secret" &&
                    <SecretSetting name={key} instanceValue={settings[key].default} onChange={handleChange} readOnly={!allowEdit} />
                }        
                { settings[key].type === "text" &&
                    <TextSetting name={key} instanceValue={settings[key].default} onChange={handleChange} readOnly={!allowEdit} />
                }
                </Table.Cell>
                <Table.Cell>
                {allowEdit &&
                <Button className="rowAction" content='Remove' icon='close' color='red' labelPosition='left' onClick={()=>removeSetting(key)}/>}
                </Table.Cell>
                </Table.Row>
            )}
                        
            {allowEdit &&      
                <Table.Row>
                    <Table.Cell>
                        <Form.Input
                            name="newSettingName"
                            value={newSettingName}
                            onChange={handleChange}
                            error={newSettingNameError}
                            maxLength="30"
                            placeholder="name"
                            fluid
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <SettingTypeSelector onChange={handleChange} />
                    </Table.Cell>
                    <Table.Cell>
                        {newSettingType === "text" &&
                            <TextSetting name='newSettingDefault' instanceValue={newSettingDefault} onChange={handleChange} readOnly={!allowEdit} />     
                        }
                        {newSettingType === "secret" &&
                            <SecretSetting name='newSettingDefault' instanceValue={newSettingDefault} onChange={handleChange} readOnly={!allowEdit} />     
                        }
                        {newSettingType === "bool" && 
                            <BooleanSetting name='newSettingDefault' instanceValue={newSettingDefault} onChange={handleChange} readOnly={!allowEdit} />        
                        }
                    </Table.Cell>
                    <Table.Cell>
                        <Button className="branded rowAction" content='Add' icon='plus' labelPosition='left' onClick={()=>addNewSetting()}/>
                    </Table.Cell>
                </Table.Row>
            }
            </Table>
        </Container>
    )

}
export default ComponentSettings