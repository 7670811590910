import { useOpportunityContext } from "../OpportunityContext"
import { Table, Accordion, Icon, Container } from "semantic-ui-react"
import './OpportunityDetails.css'
import { useState } from "react"
import OpportunityPicker from "./OpportunityPicker"
import { useFlags } from 'flagsmith/react';


const OpportunityDetails = ({linkedOpportunities, function_linkOpportunity, allowEdit}) => {
    const {opportunities} = useOpportunityContext()
    const [showDetail,setShowDetail] = useState(linkedOpportunities.length === 0 && opportunities.length>0)
    const flags = useFlags(['opportunity_linking']);

    function handleClick () {  
      setShowDetail(!showDetail)
    }

    function displayOpp(opp){
        const i = opportunities.findIndex(e => e.opportunity_id === opp)
        if(i > -1){
            return (
                <Table.Row>
                    <Table.Cell><a href={'https://okta.my.salesforce.com/'+opportunities[i].opportunity_id} target="_blank" rel="noreferrer"><Icon name="cloud" color="teal"></Icon></a></Table.Cell>
                    <Table.Cell>{opportunities[i].account_name}</Table.Cell>
                    <Table.Cell>{opportunities[i].name}</Table.Cell>
                    <Table.Cell>{opportunities[i].stage}</Table.Cell>
                    <Table.Cell>{opportunities[i].user_role}</Table.Cell>
                </Table.Row>
            )
        }
        else {
            return ("")
        }
    }

    async function addOpp(event){
        function_linkOpportunity(event.target.value)
    }

    if(flags.opportunity_linking.enabled || linkedOpportunities.length>0){
    return (
        <Accordion>
        <Accordion.Title
          active={showDetail}
          onClick={handleClick}
        >
            <Icon name='briefcase' /> Opportunity
          <Icon name='dropdown' />
        </Accordion.Title>
        <Accordion.Content active={showDetail}>
            {linkedOpportunities.length>0 &&
                <Container className="scrollable">
                <Table compact basic='very' singleLine fixed unstackable textAlign='center'>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1}></Table.HeaderCell>
                        <Table.HeaderCell>Account Name</Table.HeaderCell>
                        <Table.HeaderCell>Opportunity</Table.HeaderCell>
                        <Table.HeaderCell>Stage</Table.HeaderCell>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>
                    {linkedOpportunities.map((opp) =>
                        displayOpp(opp)
                    )}
                </Table>
                </Container>
            }
            {flags.opportunity_linking.enabled && <OpportunityPicker allowEdit={allowEdit} dismissOnChange={false} updateOpportunity={addOpp} existingOpps={linkedOpportunities}/>}   
        </Accordion.Content>
        </Accordion>
    )
    }
    else{
        return (<span><Icon name='briefcase' /> Opportunity</span>)
    }
}
export default OpportunityDetails