export const lightTheme = {
    brand: '#1A31A9',
    brandLighter: '#3F59E4',
    brandLightest:'#A1E3FF',
    cta: 'black',
    ctaContrastText: 'white',
    background: '#F4F2EC',
    backgroundLighter: '#FFFEFA',
    backgroundDarker: '#EBE3C9',
    elementBackground: 'ghostwhite',
    contrastText: 'white',
    inputBox: 'white',
    text: 'black',
    header: '#004E8F'
}

export const darkTheme = {
    brand: '#1A31A9',
    brandLighter: '#3F59E4',
    brandLightest:'#A1E3FF',
    cta: 'black',
    ctaContrastText: 'white',
    background: '#F4F2EC',
    backgroundLighter: '#FFFEFA',
    backgroundDarker: '#EBE3C9',
    elementBackground: 'ghostwhite',
    contrastText: 'white',
    inputBox: 'white',
    text: 'black',
    header: '#004E8F'
}