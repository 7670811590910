import React, {useState} from "react";
import { useOpportunityContext } from "../OpportunityContext"
import { Form, Dropdown } from 'semantic-ui-react';
import './OpportunityPicker.css'

const OpportunityPicker = ({allowEdit, updateOpportunity, dismissOnChange = true, existingOpps = []}) => {

    const {opportunities} = useOpportunityContext()
    const [selected, setSelected] = useState(null)

    function buildOptions(opportunities){
        const options = []
        if (dismissOnChange){
            options.push({
                key: '-- skip --',
                text: '-- Skip --',
                value: 'noop'
            })
        }
        opportunities.forEach(element => {
            if(!existingOpps.find((opp)=>opp === element.opportunity_id)){
                options.push(
                    {
                        key: element.name,
                        text: element.name,
                        value: element.opportunity_id
                    }
                )
            }
        });
        return options
    }

    function onChange(event, { name, value }) {
        setSelected(value)
        if(dismissOnChange){
            updateOpportunity({
                target:{
                    id: 'opportunity',
                    value: value
                }
            })
        }
    }

    function submit(){
        updateOpportunity({
            target:{
                id: 'opportunity',
                value: selected
            }
        })
    }

    if(dismissOnChange){
    return (
        <Dropdown
            placeholder='Select Opportunity'
            id="opportunities"
            name="opportunities"
            fluid
            search
            selection
            onChange={onChange}
            options={buildOptions(opportunities)}
            readOnly={!allowEdit}
            disabled={!allowEdit}
        />
    )} else{
        return (
            <Form  inline fluid>
            <Form.Group>
            <Form.Dropdown
                placeholder='Select Opportunity'
                id="opportunities"
                name="opportunities"
                className="fillFlexParent"
                search
                fluid
                selection
                value={selected}
                onChange={onChange}
                options={buildOptions(opportunities)}
                readOnly={!allowEdit}
                disabled={!allowEdit}
                style={{'flow-grow':4}}
            />
            {allowEdit ? 
                (<Form.Button icon="plus"  className="cta" onClick={submit}></Form.Button>):
                (<Form.Button icon="plus" loading className="cta" disabled></Form.Button>)}
            </Form.Group>
        </Form>
        )
    }

}
export default OpportunityPicker