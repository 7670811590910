const IdpType = ({idp}) => {
    switch (idp.type) {
        case "customer-identity":
            return (
                <span>Customer Identity Cloud</span>       
            )
        case "okta-customer-identity":
            return (
                <span>Okta Customer Identity Solution</span>    
            )
        case "workforce-identity":
            switch(idp.variant){
                case "preview":
                    return(<span>Workforce Identity Cloud Preview</span>)
                case "gcp":
                    return(<span>Workforce Identity Cloud (GCP)</span>)
                default:
                    return(<span>Workforce Identity Cloud</span>)
            }
        default:
            break;
    }
}
export default IdpType