import React, {
    useState,
    useEffect,
    useCallback
  } from "react";
import axios from 'axios'
import  Config from "../Config";
import { useParams } from "react-router-dom";
import { Container, Header, Loader, Segment, Grid, Button } from 'semantic-ui-react';
import { useAuth0 } from "@auth0/auth0-react";
import ErrorMessage from "../components/ErrorMessage";
import CategoryLabel from "../components/CategoryLabel";
import NavigationButton from "../components/NavigationButton";
import {sortObjectByKeys} from "../util/SortObjectByKeys.js"
import InstanceDetails from "../components/instance/InstanceDetails";
import { BreadcrumbList, Callout } from "@okta/odyssey-react-mui";
import { useNavigate } from "react-router";
import { detachAppFromDemonstration } from "../services/DemoAPI.js";

const AppInstanceDetails = () => {

    let params = useParams();
    const navigate = useNavigate();
    const [application, setApplication] = useState(null)
    const [waiting, setWaiting] = useState(false)
    const [error,setError] = useState(null)
    const [label, setLabel] = useState()
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getApp = useCallback(async () => {
        setApplication()
        setLabel()
        axios.get(`${Config.resourceServer.demoAPI}/demonstration/${params.demoName}/apps/${params.appId}`,
        { 
            headers: { Authorization: "Bearer " + await getAccessTokenSilently() }
        }
        )
        .then((response)=>{ 
            response.data.settings = sortObjectByKeys(response.data.settings)
            setApplication(response.data)
            setLabel(response.data.label)
         })
        .catch((error)=> { setError(error);})
    },[setApplication, setLabel, getAccessTokenSilently,params.appId, params.demoName])

    useEffect(() => {
        if (isAuthenticated) {
            getApp()
        } else {
            setApplication()
            setLabel()
        }
    }, [getApp, isAuthenticated, getAccessTokenSilently, setApplication])

    async function detachInstance() {
        setWaiting(true);
        detachAppFromDemonstration(await getAccessTokenSilently(), params.demoName, params.appId)
          .then(() => { navigate('/demo/' + params.demoName); setWaiting(false) })
          .catch((error) => { setError(error); setWaiting(false) })
      }

    return (
    <Container className="appComponent">
        <BreadcrumbList>
            <NavigationButton destination={`/`} msg="Demos" />
            <NavigationButton destination={`/demo/${params.demoName}`} msg={params.demoName} />
            <i href="">
                {application ? application.label : params.appId}
            </i>
        </BreadcrumbList>
        <Segment>  
        { application ? (
            <Container>
            <Grid stackable className="componentHeader">
                <Grid.Row columns={2}>
                    <Grid.Column width={13}>
                    <Header className="contentHeader">
                        {label}  
                    </Header>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {waiting ? (<Loader active inline />) : null}
                        <Button floated='right' disabled={waiting} type='button' onClick={() => detachInstance()}>Detach</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {application.deployedVersion < application.latestVersion &&       
                <Callout
                severity="info"
                title="New version available"
                text="An updated version of this application is available, features and behaviour of this application may differ based on your deployed version. To update to the latest version detach and reattach this application."
                />
            }
            <Header.Subheader className="meta">
                    <CategoryLabel category={application.category}/>
                    { application ? (
                    <InstanceDetails instance={application} type='application' onUpdate={setLabel} onError={setError}/>
                    ) : <Loader active inline /> }
            </Header.Subheader>
            </Container>
        ): (<Container><Header className="contentHeader">{params.demoName}: Application Instance</Header><Loader active inline /></Container>)}
        {error ? (
            <ErrorMessage error={error}/>
        ):null}
        </Segment>      
    </Container>
    )
}
export default AppInstanceDetails;