import { Icon } from 'semantic-ui-react';

const DemoTypeLabel = ({type}) => {
    switch (type) {
        case "opportunity":
            return (
                <span>
                    <Icon name='briefcase' /> Opportunity
                </span>
            )
        case "enablement":
            return (
                <span>
                    <Icon name='student' /> Enablement
                </span>
            )
        case "lab":
                return (
                    <span>
                        <Icon name='lab' /> Lab
                    </span>
                )
        default:
            break;
    }

}
export default DemoTypeLabel