import React from "react";
import { Dropdown } from 'semantic-ui-react';
import { StorytimeTemplates } from "../util/StorytimeTemplates";

const TemplatePicker = ({allowEdit, updateApplication}) => {


    function buildOptions(){
        const options = [{
            key: '-- skip --',
            text: '-- Skip --',
            value: 'noop'
        }]
        StorytimeTemplates.forEach(element => {
            options.push(
                {
                    key: element.name,
                    text: element.displayName,
                    value: element.name
                }
            )
        });
        return options
    }

    function onChange(event, { name, value }) {
        updateApplication({
            target:{
                id: 'templates',
                value: value
            }
        })
    }

    return (
        <Dropdown
            placeholder='Select a template'
            id="templates"
            name="templates"
            fluid
            search
            selection
            onChange={onChange}
            options={buildOptions()}
            readOnly={!allowEdit}
            disabled={!allowEdit}
        />
    )

}
export default TemplatePicker