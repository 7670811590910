import { Form } from 'semantic-ui-react';
import { BooleanSettingOptions } from '../ComponentSettingsConstants';

const BooleanSetting = ({name, instanceValue, defaultValue, readOnly=false, onChange}) => {
  return <Form.Dropdown 
      id='settings'
      key={name}
      name={'settings.' + name}
      value={instanceValue !== undefined ? instanceValue : null}
      onChange={onChange}
      options={BooleanSettingOptions}
      placeholder={(defaultValue !== undefined ? defaultValue.toString() : '') + ' (default)'}
      readOnly={readOnly}
      disabled={readOnly}
      selection
      fluid
      inline
      />
}

export default BooleanSetting;

