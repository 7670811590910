import { useState } from 'react';
import { Container, Header, Button, Segment, Divider } from 'semantic-ui-react';

const Productivity = ({ step, func_complete }) => {

    const [waiting, setWaiting] = useState(false)

    async function microsoft() {
        setWaiting(true)
        var microsoftStep = step
        microsoftStep.id = step.id.microsoft
        func_complete(step)
    }

    async function google() {
        setWaiting(true)
        var googleStep = step
        googleStep.id = step.id.google
        func_complete(step)
    }

    async function skip() {
        setWaiting(true)
        func_complete()
    }

    return (
        <Container>
            <Container>
                <Segment basic >
                    <Header>Productivity Apps</Header>
                    <Container>
                        <p>
                            Allowing seamless interconnectivity with services that provide documents, calendars, and mail for your organisation is paramount. 
                            With Okta's Integration Network it has never been easier to connect these services and underpin them with strong Identity and Access Management.
                            
                            Choose your preferred productivity suite to include it from the Okta Integration Network and see how Okta's rich integrations will help you manage your workforce.
                        </p>
                    </Container>
                    <Divider hidden />
                    <Container>
                        <Button icon='google' content='Google Workspace' disabled={waiting} className="" onClick={google} />
                        <Button icon='microsoft' content='Office365' disabled={waiting} className="" onClick={microsoft} />
                        <Button disabled={waiting} className="" onClick={skip}>None</Button>
                    </Container>
                </Segment>
            </Container>
        </Container>
    )
}
export default Productivity