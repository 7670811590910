import { Card, Icon, Button, Popup, Image } from 'semantic-ui-react';
import './ApplicationCard.css'
import CollaboratorList from './collaboration/CollaboratorList';

const ApplicationCard = ({ application, waiting, attachEvent }) => {
    return (
        <Card key={application.application_id}>
            <Card.Content className='cardHeader'>
                <Card.Header>
                    {application.name}
                    {application.logo && <Image floated='right' size='mini' src={application.logo} />}
                </Card.Header>
                <Card.Meta>
                    {application.category !== "managed" &&
                        <div><CollaboratorList entity={application} /></div>
                    }
                    <a target="_blank" rel="noreferrer" href={application.baseAddress}>{new URL(application.baseAddress).host}</a>
                </Card.Meta>
            </Card.Content>
            <Card.Content className="cardContent">
                <Card.Description className="pre-line-text">
                    {application.description}
                </Card.Description>
            </Card.Content>
            {
                application.uses?.managementCredentials &&
                <Card.Content extra >
                    <Popup content='This application will be able to make changes to your tenant configuration. If you have already deployed applications or configured your tenant attaching this app may create conflicts.' trigger={<span><Icon name='warning circle' size='' /> Can configure tenant</span>} />
                </Card.Content>
            }
            <Card.Content extra className='cardFoot'>
                {application.state === "active" ? (
                    <Button className="card-action branded" disabled={waiting} onClick={() => attachEvent(application.application_id)}>
                        Attach
                    </Button>
                ) : application.state === "preview" ? (
                    <Button className="card-action branded" disabled={waiting} onClick={() => attachEvent(application.application_id)}>
                        Attach Preview <Icon name="flask" />
                    </Button>
                ) : (
                    <Button className="card-action" disabled>
                        {application.state}
                    </Button>
                )}
            </Card.Content>
        </Card >
    )

}
export default ApplicationCard