import { useEffect, useState } from 'react';
import { Container, Button, Header, Segment, Icon, Message, Image, Grid } from 'semantic-ui-react';
import cryptoJs from 'crypto-js';
import { useAuth0 } from "@auth0/auth0-react";
import { useFlags } from 'flagsmith/react';
import { useSearchParams } from "react-router-dom";

const Heropa = () => {

    const { user } = useAuth0();
    const flags = useFlags(['heropa']);
    const [searchParams] = useSearchParams();
    const [unconfigured, setUnconfigured] = useState(false);
    const [templateId, setTemplateId] = useState()

    const [resourceLinkId, setResourceLinkId] = useState() //this will be demo name?
    const [email, setEmail] = useState()
    const [oauthNonce] = useState(generateRandomString(10))
    const [oauthTimestamp] = useState(Math.floor(Date.now() / 1000))
    const [signature,setSignature] = useState();

    const url = 'https://lti-oktaeng.heropa.com/lti/heropa/'
    const roles = "Learner"

    const fullName = "DemoUser"
    const givenName = "Demo"
    const familyName = "User"

    //Heropa variables
    const heropaInstance = "oktaeng.heropa.com"
    const errorReturnUrl = "https://demo.okta.com/error.html"
    const ltiVersion = "LTI-1p0"
    const ltiType = "basic-lti-launch-request"
    const oauthCallback = "about:blank"
    const oauthVersion = "1.0"
    const oauthSignatureMethod = "HMAC-SHA1"
    const oauthConsumerKey = "okta:se"
    const hashKey = "wn73iwu7nhs0"

    useEffect(() => {
        setEmail(user.email)
    }, [user])

    useEffect(() => {
        if (searchParams.get("templateId") && searchParams.get("demoName")) {
            setTemplateId(searchParams.get("templateId"))
            setResourceLinkId(escapeDemoName(searchParams.get("demoName")))
            var content = {
                "launch_presentation_return_url": errorReturnUrl,
                "lis_person_contact_email_primary": email,
                "lis_person_name_family": familyName,
                "lis_person_name_full": fullName,
                "lis_person_name_given": givenName,
                "lti_message_type": ltiType,
                "lti_version": ltiVersion,
                "oauth_callback": oauthCallback,
                "oauth_consumer_key": oauthConsumerKey,
                "oauth_nonce": oauthNonce,
                "oauth_signature_method": oauthSignatureMethod,
                "oauth_timestamp": oauthTimestamp,
                "oauth_version": oauthVersion,
                "resource_link_id": resourceLinkId,
                "roles": roles,
                "tool_consumer_instance_guid": heropaInstance,
                "user_id": email
            };
            var parsedContent = new URLSearchParams(Object.entries(content))
            var payload = "POST&" + encodeURIComponent(url + templateId) + '&' + encodeURIComponent(parsedContent.toString());
            var mac = cryptoJs.HmacSHA1(payload, hashKey + "&");
            setSignature(cryptoJs.enc.Base64.stringify(mac))
        }
        else {
            setUnconfigured(true)
        }
    }, [email, hashKey, searchParams, resourceLinkId, oauthNonce, oauthTimestamp, templateId])

    useEffect(()=>{
        if(signature!= null&&document.getElementById("ltiLaunchForm")!= null){
        document.getElementById("ltiLaunchForm").submit()
        } else{
            console.log("signature:"+signature)
            console.log("button:"+document.getElementById("ltiLaunchForm"))
        }
    },[signature])

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    function escapeDemoName(name) {
        return name.replaceAll('-', "")
    }

    return (
        <Container className=''>
            <Header>Virtual Infrastructure</Header>
                <div>
                    {flags.heropa?.enabled ?
                        (
                            <Container>
                                <Grid columns={2} stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Segment basic>Okta provides the virtual infrastructure through our partner Heropa. These environments are available to you for short time limited demonstration and will be automatically destroyed once your session expires.</Segment>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Image src="https://cdn.demo.okta.com/images/Heropa-logo.webp" ></Image>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                {unconfigured ?
                                    (
                                        <Message negative icon>
                                            <Icon name='warning' />
                                            <Message.Content>
                                                <Message.Header>Something went wrong</Message.Header>
                                                <p>We could not determine the demonstration you are trying to run.</p>
                                            </Message.Content>
                                        </Message>
                                    )
                                    :
                                    (
                                        <Grid>
                                            <form id="ltiLaunchForm" name="ltiLaunchForm" method="POST" action={url + templateId}>
                                                <input type="hidden" id="user_id" name="user_id" value={email} />
                                                <input type="hidden" id="roles" name="roles" value={roles} />
                                                <input type="hidden" id="resource_link_id" name="resource_link_id" value={resourceLinkId} />
                                                <input type="hidden" id="lis_person_name_full" name="lis_person_name_full" value={fullName} />
                                                <input type="hidden" id="lis_person_name_family" name="lis_person_name_family" value={familyName} />
                                                <input type="hidden" id="lis_person_name_given" name="lis_person_name_given" value={givenName} />
                                                <input type="hidden" id="lis_person_contact_email_primary" name="lis_person_contact_email_primary" value={email} />
                                                <input type="hidden" id="tool_consumer_instance_guid" name="tool_consumer_instance_guid" value={heropaInstance} />
                                                <input type="hidden" id="launch_presentation_return_url" name="launch_presentation_return_url" value={errorReturnUrl} />
                                                <input type="hidden" id="lti_version" name="lti_version" value={ltiVersion} />
                                                <input type="hidden" id="lti_message_type" name="lti_message_type" value={ltiType} />
                                                <input type="hidden" id="oauth_callback" name="oauth_callback" value={oauthCallback} />
                                                <input type="hidden" id="oauth_consumer_key" name="oauth_consumer_key" value={oauthConsumerKey} />
                                                <input type="hidden" id="oauth_version" name="oauth_version" value={oauthVersion} />
                                                <input type="hidden" id="oauth_nonce" name="oauth_nonce" value={oauthNonce} />
                                                <input type="hidden" id="oauth_timestamp" name="oauth_timestamp" value={oauthTimestamp} />
                                                <input type="hidden" id="oauth_signature_method" name="oauth_signature_method" value={oauthSignatureMethod} />
                                                <input type="hidden" required id="oauth_signature" name="oauth_signature" value={signature} />
                                                {signature && <Button id="button" className="branded" size='huge' type="submit">Launch Virtual Infrastructure</Button>}
                                            </form>
                                        </Grid>
                                    )
                                }
                            </Container>
                        ) : (
                            <Segment basic>You do not currently have access to virtual infrastructure through builder.demo.okta.
                            </Segment>
                        )
                    }
                </div>
        </Container >
    )

}
export default Heropa