import React from 'react'
import { Menu, Icon, Popup, Image, Grid, List } from 'semantic-ui-react';
import './nav.css'
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";


const Nav = () => {
    const {
        isAuthenticated,
        user,
        loginWithRedirect,
        logout
    } = useAuth0();

    const navigate = useNavigate();
    function handleOnClick(path) {
        navigate(path)
    }

    return (
        <Menu className="navMenu" fluid borderless stackable>
            <Menu.Item as='a' header onClick={() => handleOnClick('/')}>
                <Icon name='cube' className="navLogo" />Demo Builder
            </Menu.Item>
            <Menu.Menu position='right'>
                {isAuthenticated && (
                    <Menu.Item>
                        <Popup on={"click"} trigger={<Image className='userAvatar userControl' src={user.picture} avatar size='mini' referrerPolicy='no-referrer' />} hoverable>
                            <Grid centered divided>
                                <Grid.Column textAlign='center'>
                                    <List verticalAlign='middle' relaxed>
                                        <List.Item className="userControl" onClick={() => handleOnClick('settings')} >
                                            <Icon name='cog' /> Settings
                                        </List.Item>
                                        <List.Item className="userControl" onClick={logout}>
                                            Logout
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid>
                        </Popup>
                    </Menu.Item>
                )}
                {!isAuthenticated && (
                    <Menu.Item onClick={loginWithRedirect}>Login</Menu.Item>
                )}
            </Menu.Menu>
        </Menu>
    );
};
export default Nav;
