import { Button, Toast, ToastStack } from "@okta/odyssey-react-mui";
import { CopyIcon } from "@okta/odyssey-react-mui/icons";
import React, { useState } from "react";
import { Box } from "@mui/material";

const CopyableField = ({ label, value, hint, childField }) => {

    const [toast, setToast] = useState(false);

    const handleHide = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setToast(false);
    };

    function handleClick() {
        setToast(true);
        navigator.clipboard.writeText(value);
    }

    return (<>
        <Box display="flex" alignItems="center">
            {childField}
            <Box sx={{pt:"0.55em", pl:"4px"}}>
            <Button onClick={handleClick} startIcon={<CopyIcon />} size='small' variant='secondary' />
            </Box>
        </Box>
        <ToastStack>
            <Toast
                isVisible={toast}
                autoHideDuration={2000}
                onHide={handleHide}
                role='status'
                severity="info"
                text={label + " copied to clipboard"}
            />
        </ToastStack>
    </>
    )

}

export default CopyableField