import { Form } from 'semantic-ui-react';

const TextSetting = ({name, instanceValue, defaultValue = '', readOnly=false, onChange}) => {
    return <Form.Input
            id='settings'
            key={name}
            name={'settings.' + name}
            value={instanceValue ? instanceValue : ''}
            onChange={onChange}
            maxLength='1024'
            placeholder={defaultValue + ' (default)'}
            readOnly={readOnly}
            fluid
            inline
            />
}

export default TextSetting;