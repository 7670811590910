import React, { useState } from "react";
import { Container, Header, Segment, Divider, Button, Form, Icon, Checkbox, Table } from 'semantic-ui-react';
import { useFlags } from 'flagsmith/react';
import NavigationButton from "../components/NavigationButton";
import flagsmith from "flagsmith";
import { useAuth0 } from "@auth0/auth0-react";
import config from "../Config";
import AWSRegionSetting from "../components/settings/AWSRegionSetting";
import RDPClientPreference from "../components/settings/RDPClientPreference";


const Settings = () => {
    const flags = useFlags(['aws_linking', 'demonstration_limits', 'app_limits', 'resource_limits', 'one_click_guide_page']);
    const { user } = useAuth0();
    const [awsAccountLinked, setAWSAccountLinked] = useState(flagsmith.getTrait("aws_account_id"))
    const [awsAccountId, setAwsAccountId] = useState(flagsmith.getTrait("aws_account_id"))
    const [awsPreferredRegion, setAwsPreferredRegion] = useState(flagsmith.getTrait("aws_preferred_region"))
    const [rdpPreference, setRdpPreference] = useState(flagsmith.getTrait('rdp_preference'))

    function handleChange(event) { if (event.target.name === "awsAccountId") { setAwsAccountId(event.target.value); } }

    return (
        <Container className="appComponent">
            <NavigationButton destination='/' />
            <Segment>
                <Header className="contentHeader">Settings</Header>

                <Container>
                    <Header as='h3'>Your Account</Header>
                    <Table singleLine basic='very'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell width={3}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Row><Table.Cell><b>Email</b></Table.Cell><Table.Cell>{user.email}</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell><b>Account Type</b></Table.Cell><Table.Cell>{flagsmith.getTrait("connection")}</Table.Cell></Table.Row>
                        {flagsmith.getTrait("connection") !== 'guest' &&
                            <Table.Row><Table.Cell><b>Enablement Limit</b></Table.Cell><Table.Cell>{flags.demonstration_limits.value}</Table.Cell></Table.Row>
                        }
                        <Table.Row><Table.Cell><b>Application Limit</b></Table.Cell><Table.Cell>{flags.app_limits.value}</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell><b>Resource Limit</b></Table.Cell><Table.Cell>{flags.resource_limits.value}</Table.Cell></Table.Row>
                    </Table>
                </Container>

                <Container>
                    <Divider />
                    <Header as='h3'>Preferences</Header>
                    <Table singleLine basic='very'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell width={3}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Row>
                            <Table.Cell><b>Show one-click guides</b></Table.Cell>
                            <Table.Cell>
                                <Checkbox checked={!flagsmith.getTrait("hide_one_click_guide")} onClick={() => { flagsmith.setTrait("hide_one_click_guide", !flagsmith.getTrait("hide_one_click_guide")) }}></Checkbox>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>RDP Client</b></Table.Cell>
                            <Table.Cell>
                                <RDPClientPreference name='rdp_Preference' instanceValue={rdpPreference} defaultValue='Microsoft' onChange={(event, { name, value }) => { flagsmith.setTrait('rdp_preference', value); setRdpPreference(value) }} />
                            </Table.Cell>
                        </Table.Row>
                    </Table>

                </Container>

                {
                    flags.aws_linking.enabled &&
                    <Container>
                        <Divider />
                        <Icon name="aws" size='huge' aria-label="AWS" />
                        {awsAccountLinked !== undefined
                            ? (<Container>
                                <Table singleLine basic='very'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={3}></Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell width={3}></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Row>
                                        <Table.Cell>
                                            <b>Default AWS Account ID</b>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {awsAccountId}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center"><Button onClick={() => { flagsmith.setTrait("aws_account_id", null); setAWSAccountLinked(undefined) }}>Reset</Button></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            <b>Default Region</b>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <AWSRegionSetting name='awsRegion' instanceValue={awsPreferredRegion} defaultValue='us-east-1' onChange={(event, { name, value }) => { flagsmith.setTrait('aws_preferred_region', value); setAwsPreferredRegion(value) }} />
                                        </Table.Cell>
                                    </Table.Row>
                                </Table>
                                <Segment secondary basic>
                                    <p>To complete the account linking process run the CloudFormation script below to the role required for demo.okta to deploy components in your AWS.</p>
                                    <a target="_blank" rel="noreferrer" href={config.features.awsCrossAccountLink.replace("<region>", awsPreferredRegion)}>
                                        <Button icon="external alternate" content="Perform Linking in AWS" />
                                    </a>
                                </Segment>
                            </Container>)
                            : (<Form onSubmit={() => { flagsmith.setTrait("aws_account_id", awsAccountId); setAWSAccountLinked(awsAccountId) }}>
                                <Form.Field required>
                                    <label>AWS Account ID</label>
                                    <input pattern="^\d{12}$" title="12 digit AWS account id" placeholder="" name="awsAccountId" value={awsAccountId} onChange={handleChange}></input>
                                </Form.Field>
                                <Button type='submit'>Save</Button>
                            </Form>)
                        }
                    </Container>
                }
            </Segment >

        </Container >
    )
}
export default Settings;