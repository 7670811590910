import { useState, useEffect } from 'react';
import { Container, Header, Button, Segment, Divider } from 'semantic-ui-react';
import flagsmith from "flagsmith";
import { useFlags } from 'flagsmith/react';

const ADPrompt = ({ step, func_complete }) => {

    const flags = useFlags(['aws_linking']);
    const [awsAccountId] = useState(flagsmith.getTrait("aws_account_id"))
    const [waiting, setWaiting] = useState(false)

    async function submit() {
        setWaiting(true)
        var action = step
        action.settings = { awsAccountId: awsAccountId }
        func_complete(step)
    }

    async function skip() {
        setWaiting(true)
        func_complete()
    }

    useEffect(() => {
        if (!flags['aws_linking'].enabled || !awsAccountId) {
            func_complete()
        }
    }, [flags, awsAccountId, func_complete])

    return (
        <Container>
            <Container>
                <Segment basic >
                    <Header>Active Directory</Header>
                    <Container>
                        <p>
                            Active Directory (AD) is a widely used Identity Source for small and medium sized enterprises. Typical deployments manage user account information including attributes and passwords as well as group memberships. 
                            When adopting Okta as an Identity Platform, it is common to integrate with AD so existing users and management processes are not impacted. 
                            Integration can also help to understand how to migrate away from Active Directory as the primary source of identity information.

                            As you have your AWS account linked, you can deploy an Active Directory server within your AWS account; enabling you to populate and connect your Workforce Tenant with AD to showcase these use cases.
                            
                            This component deploys a Windows Server, configures Active Directory and downloads Okta AD Agent ready for connection to your new Identity Provider.
                        </p>
                        <p>
                            Virtual infrastructure will be automatically removed when this demonstration is deleted.
                        </p>
                    </Container>
                    <Divider hidden />
                    <Container>
                        <Button disabled={waiting} className="branded" onClick={submit}>Deploy</Button>
                        <Button disabled={waiting} className="" onClick={skip}>Skip</Button>
                    </Container>
                </Segment>
            </Container>
        </Container>
    )
}
export default ADPrompt