import { Form } from 'semantic-ui-react';

const RDPClientConstants = [
  {
    key: 'microsoft',
    text: 'Microsoft',
    value: 'microsoft'
  },
  {
    key: 'tsx',
    text: 'Royal TSX',
    value: 'tsx'
  }
]

const RDPClientPreference = ({ name, instanceValue, defaultValue, readOnly = false, onChange }) => {
  return <Form.Dropdown
    id='settings'
    key={name}
    name={'settings.' + name}
    value={instanceValue !== undefined ? instanceValue : null}
    onChange={onChange}
    options={RDPClientConstants}
    placeholder={(defaultValue !== undefined ? defaultValue.toString() : '') + ' (default)'}
    readOnly={readOnly}
    disabled={readOnly}
    selection
    fluid
    inline
  />
}

export default RDPClientPreference;


