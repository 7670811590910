import React, {
    useState,
    useEffect,
    useCallback
} from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Config from "../Config";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { Container, Header, Form, Popup, Loader, Button, Segment, Input, Divider, Icon } from 'semantic-ui-react';
import ErrorMessage from "../components/ErrorMessage";
import { createIDP, createDemonstration, getDemonstration, attachAppToDemonstration, linkDemonstrationToOpp, updateApplicationInstance, getIDP } from "../services/DemoAPI";
import OpportunityPicker from "../components/OpportunityPicker";
import { useOpportunityContext } from "../OpportunityContext"
import { useFlags } from 'flagsmith/react';
import NavigationButton from "../components/NavigationButton";
import { getTemplateSettings } from "../util/StorytimeTemplates";
import TemplatePicker from "../components/TemplatePicker";
import { generateDemoName } from "../util/GenerateDemoNames";
import { useDemoContext } from "../DemoContext";

const LibraryDemonstrationCreate = () => {

    let params = useParams();
    const navigate = useNavigate();
    const demoContext = useDemoContext();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { opportunities } = useOpportunityContext()
    const flags = useFlags(['opportunity_linking', 'storytime_templates']);

    const [stage, setStage] = useState('invite')
    const [oppLinked, setOppLinked] = useState(false)
    const [template, setTemplate] = useState("")
    const [templateApplied, setTemplateApplied] = useState(false)
    const [displayMessage, setDisplayMessage] = useState("")

    const [name, setName] = useState(generateDemoName())
    const [nameError, setNameError] = useState()

    const [application, setApplication] = useState()
    const [demo, setDemo] = useState()
    const [idp, setIdp] = useState()

    const [error, setError] = useState(null)


    const getApp = useCallback(async () => {
        axios.get(Config.resourceServer.demoAPI + '/applications/' + params.appId,
            {
                headers: { Authorization: "Bearer " + await getAccessTokenSilently() }
            }
        )
            .then((response) => {
                setApplication(response.data)
            })
            .catch((error) => { setError(error); })
    }, [setApplication, getAccessTokenSilently, params.appId])

    function validateForm() {
        let result = true

        //test alphanumeric and hyphen, start & end cannot be hyphen
        const tenantPattern = '^(?!W|-)((?!admin|okta)[a-z-0-9]){3,63}$'
        const tenantRegex = new RegExp(`^${tenantPattern}$`)

        if (!name || name.length < 3 || name.length > 63 || !tenantRegex.test(name)) {
            setNameError("Name must be between 3 and 63 characters and include only alphanumeric and hyphen. This must not contain the words 'admin' or 'okta'.")
            result = false
        } else {
            setNameError()
        }
        return result
    }

    function handleChange(event) {
        switch (event.target.id) {
            case "name":
                setName(event.target.value)
                break
            default:
                break;
        }
    }

    async function submitForm() {
        if (validateForm()) {
            setStage("provision")
            setDisplayMessage("Launching Identity Cloud...")
            try {
                var idp = await createIDP(await getAccessTokenSilently(), name, "customer-identity")
                setIdp(idp.data)
            } catch (err) {
                setError(err)
                setStage('error')
            }
        }
    }

    async function linkOpp(event) {
        if (event.target.value !== 'noop') {
            linkDemonstrationToOpp(await getAccessTokenSilently(), name, event.target.value)
            setOppLinked(true)
        }
    }

    async function requestTemplate(event) {
        if (event.target.value !== 'noop') {
            setTemplate(event.target.value)
        }
    }

    useEffect(() => {
        if (demo) {
            if (demo.data.state !== "active") {
                if (demo.data.state === "error") {
                    setError("Demonstration entered error state.")
                } else {
                    if (demo.data.state === "queued") {
                        setDisplayMessage("Waiting in queue.")
                    }
                    else if (demo.data?.app_instances?.length === 1) {
                        setDisplayMessage("Deploying your demonstration.")
                    }
                    var handle = (
                        setTimeout(async () => {
                            setDemo(await getDemonstration(await getAccessTokenSilently(), name))
                        }, 2000))
                    return () => {
                        clearTimeout(handle)
                    }
                }
            }
            else {
                if (demo.data.app_instances.length === 0 && stage !== "deploying") {
                    (async () => {
                        setDisplayMessage("Attaching " + application.name)
                        try {
                            setStage("deploying")
                            if (!template) {
                                await attachAppToDemonstration(await getAccessTokenSilently(), name, application.application_id)
                            } else {
                                await attachAppToDemonstration(await getAccessTokenSilently(), name, application.application_id, undefined, getTemplateSettings(template))
                                setTemplateApplied(true)
                            }
                        } catch (err) {
                            setStage('error')
                            setError(err)
                        }
                        setDemo(await getDemonstration(await getAccessTokenSilently(), name))
                    })()
                } else {
                    if (template && !templateApplied) {
                        (async () => {
                            try {
                                setDisplayMessage("Applying template.")
                                await updateApplicationInstance(await getAccessTokenSilently(), name, application.application_id, application.name, getTemplateSettings(template))
                                setTemplateApplied(true)
                            } catch (err) {
                                //suppress fails
                            }
                        })()
                    } else {
                        //everything is ready
                        if (demo.data.state === "active" && demo.data.app_instances.length === 1) {
                            window.open(demo.data.app_instances[0].link)
                            demoContext.refreshContext();
                            navigate('/demo/' + name, { replace: true, state: { demo: demo.data, idp: idp } })
                        }
                    }
                }
            }
        }
    }, [demo, idp, getAccessTokenSilently, application?.name, application?.application_id, name, navigate, template, templateApplied, stage, demoContext])

    useEffect(() => {
        if (isAuthenticated) {
            getApp()
        } else {
            setApplication()
        }
    }, [getApp, isAuthenticated, getAccessTokenSilently, setApplication])

    useEffect(() => {
        if (idp) {
            if (idp.state === 'error') {
                setError("Failed to provision identity cloud.")
            }
            else if (idp.state === 'queued') {
                var handle = (
                    setTimeout(async () => {
                        var response = await getIDP(await getAccessTokenSilently(), idp.idp_id)
                        setIdp(response.data)
                    }, 2000))
                return () => {
                    clearTimeout(handle)
                }
            }
            else {
                (async () => {
                    setDisplayMessage("Creating Demonstration...")
                    var demo = await createDemonstration(await getAccessTokenSilently(), name, "opportunity", idp.idp_id, "one-click")
                    setDemo(demo)
                })()
            }
        }
    }, [idp, getAccessTokenSilently, name])

    function renderOppComponent() {
        return (
            <Container>
                {!oppLinked && flags.opportunity_linking.enabled && opportunities.length > 0 &&
                    <Container>
                        <Divider />
                        <Segment basic >
                            <Header>Link your opportunity</Header>
                            <OpportunityPicker allowEdit={true} updateOpportunity={linkOpp} />
                        </Segment>
                    </Container>
                }
            </Container>
        )
    }

    function renderTemplateComponent() {
        return (
            <Container>
                {!template && application.name === 'Storytime' && flags.storytime_templates.enabled &&
                    <Container>
                        <Divider />
                        <Segment basic >
                            <Header>Select a template</Header>
                            <TemplatePicker allowEdit={true} updateApplication={requestTemplate} />
                        </Segment>
                    </Container>
                }
            </Container>
        )
    }

    function renderStage(stage) {
        switch (stage) {
            case 'invite':
                return (
                    <Container>
                        {application ? (
                            <Container>
                                <Header className="contentHeader">
                                    New {application.name} demonstration
                                </Header>
                                <Divider className="noTopMargin" />
                                <Container className="pre-line-text">
                                    {application.description}
                                </Container>
                                <Divider />
                                <Form>
                                    <Form.Field
                                        id='name'
                                        icon={{
                                            name: 'refresh',
                                            link: true,
                                            onClick: generateDemoName
                                        }}
                                        label={<label>Demonstration name <Popup
                                            content={"Enter a unique name for your demonstration. " +
                                                "This name will be visible in the identity provider tenant as well as some applications. "}
                                            trigger={<span><Icon name='info circle' /></span>} />
                                        </label>}
                                        control={Input}
                                        value={name}
                                        onChange={handleChange}
                                        error={nameError}
                                        onKeyUp={validateForm}
                                        style={{ 'textTransform': 'lowercase' }}
                                        required
                                    />
                                    <Button className="branded" type='submit' onClick={submitForm}>Create</Button>
                                </Form>
                            </Container>
                        ) : (
                            <Loader active inline />
                        )}
                    </Container>)
            case 'provision':
                return (
                    <Container>
                        <Header className="contentHeader">Demonstration: {name}</Header>
                        <Divider className="noTopMargin" />
                        <Container textAlign="center">
                            <Loader active inline indeterminate content={displayMessage} />
                        </Container>
                        {renderTemplateComponent()}
                    </Container>
                )
            case 'deploying':
                return (
                    <Container>
                        <Header className="contentHeader">Demonstration: {name}</Header>
                        <Divider className="noTopMargin" />
                        <Container textAlign="center">
                            <Loader active inline indeterminate content={displayMessage} />
                        </Container>
                        {renderOppComponent()}
                        {renderTemplateComponent()}
                    </Container>
                )
            case 'error':
                return (
                    <Container>
                        <Header className="contentHeader">Demonstration: {name}</Header>
                        <Divider className="noTopMargin" />
                        <Container textAlign="center">
                            <ErrorMessage error={error} retryAction={() => navigate(0)} />
                        </Container>
                    </Container>
                )
            default:
        }
        return
    }


    return (

        <Container className="appComponent">
            <NavigationButton destination="/" msg="Show my demos" />
            <Segment>
                {renderStage(stage)}
            </Segment>
        </Container>
    );
}

export default LibraryDemonstrationCreate;