import { useState } from 'react';
import { Container, Header, Form, Button, Segment, Input, Divider, Dropdown } from 'semantic-ui-react';

const FGAPrompt = ({ step, demoName, func_complete }) => {

    const jurisdictionOptions = [
        { key: 'us', value: 'us', text: 'US' },
        { key: 'eu', value: 'eu', text: 'EU' },
        { key: 'au', value: 'au', text: 'AU' },
      ]

    const [waiting, setWaiting] = useState(false)
    const [environment, setEnvironment] = useState("us")
    const [storeId, setStoreId] = useState();
    const [storeIdError, setStoreIdError] = useState()
    const [clientId, setClientId] = useState();
    const [clientIdError, setClientIdError] = useState()
    const [clientSecret, setClientSecret] = useState();
    const [clientSecretError, setClientSecretError] = useState()

    async function submit() {
        setWaiting(true)
        if(validateForm){
            var action = step
            action.settings = { FGA_ENVIRONMENT: environment, FGA_STORE_ID: storeId, FGA_CLIENT_ID:  clientId, FGA_CLIENT_SECRET: clientSecret }
            func_complete(step)
        }
        setWaiting(false)
    }

    function validateForm() {
        let  valid = true
        const storeRegEx = /^[0-9A-Z]+$/;
        if(!storeRegEx.test(storeId)){
            setStoreIdError("Store ID must be an alphanumeric.");
            valid = false
        } else { console.log("clear error");setStoreIdError(undefined) }
        const clientIdRegEx = /^[0-9a-zA-Z]+$/;
        if(!clientIdRegEx.test(clientId)){
            setClientIdError("API Credential ID must be an alphanumeric.")
            valid = false
        } else { setClientIdError(undefined) }
        const clientSecretRegEx = /^[0-9a-zA-Z_-]+$/;
        if(!clientSecretRegEx.test(clientSecret)){
            setClientSecretError("API Credential Secret must be an alphanumeric")
            valid = false
        } else {setClientSecretError(undefined)}     
        return valid
    }

    function handleChange(event, { id, value }) {
        switch(id){
            case 'environment':
                setEnvironment(value)
                break
            case 'storeId':
                setStoreId(value);
                break;
            case 'clientId':
                setClientId(value);
                break;
            case 'clientSecret':
                setClientSecret(value);
                break;
            default:
                console.log("Handler unset")
                console.log(id)
                console.log(value)
        }

    }

    return (
        <Container>
            <Container>
                <Segment basic >
                    <Header>FGA</Header>
                    <Container>
                    An Okta FGA account is required for this demo. Follow the steps below to create a new FGA store and integrate it with your demo.
                    <ol>
                        <li>Go to <a href='https://fga.dev' target='_blank' rel="noreferrer">fga.dev</a>, register for a new account or sign-in with your existing account</li>
                        <li>Create a new store; from the top left of the page press 'Select Store', select 'Add new Store'</li>
                        <li>Enter a name for your store</li>
                        <li>Select 'Finish'</li>
                    </ol>

                    You will require a new API credential to integrate your store with the demo app.
                    <ol>
                        <li>Select your store</li>
                        <li>Select 'Settings'</li>
                        <li>Select 'Create Credentials'</li>
                    </ol>
                    </Container>
                    <Divider hidden />
                    <Form>
                        <Form.Field
                            id='environment'
                            label={<label>Store Jurisdiction</label>}
                            control={Dropdown}
                            options={jurisdictionOptions}
                            search
                            required={true}
                            value={environment}
                            onChange={handleChange}>
                        </Form.Field>
                        <Form.Field
                            id='storeId'
                            label={<label>Store ID</label>}
                            control={Input}
                            required={true}
                            value={storeId}
                            onChange={handleChange}
                            onKeyUp={validateForm}
                            error={storeIdError}
                            style={{ 'textTransform': 'lowercase' }}
                        />
                        <Form.Field
                            id='clientId'
                            label={<label>API Credential ID</label>}
                            control={Input}
                            required={true}
                            value={clientId}
                            onChange={handleChange}
                            onKeyUp={validateForm}
                            error={clientIdError}
                            style={{ 'textTransform': 'lowercase' }}
                        />
                        <Form.Field
                            id='clientSecret'
                            label={<label>API Secret</label>}
                            control={Input}
                            required={true}
                            value={clientSecret}
                            onChange={handleChange}
                            onKeyUp={validateForm}
                            error={clientSecretError}
                            type='password'
                            style={{ 'textTransform': 'lowercase' }}
                        />
                        <Button disabled={waiting} className="branded" type='submit' onClick={submit}>Apply</Button>
                    </Form>
                </Segment>
            </Container>
        </Container>
    )
}
export default FGAPrompt