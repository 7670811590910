export const DemonstrationDiscoveryOptions = [
    {
        key: 'subdomain',
        text: 'Subdomain',
        value: 'subdomain'
    },
    {
        key: 'queryparam',
        text: 'Query',
        value: 'queryparam'
    },
    {
        key: 'none',
        text: 'None',
        value: 'none'
    }
]