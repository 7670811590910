import React, {
    useState,
    useEffect,
    useCallback
  } from "react";
import axios from 'axios'
import  Config from "../Config";
import { useParams } from "react-router-dom";
import { Container, Header, Loader, Card, Divider } from 'semantic-ui-react';
import { useAuth0 } from "@auth0/auth0-react";
import ErrorMessage from "../components/ErrorMessage";
import { useNavigate } from "react-router";
import NavigationButton from "../components/NavigationButton";
import { BreadcrumbList } from "@okta/odyssey-react-mui";

import { useFlags } from 'flagsmith/react';
import './DemoApps.css'
import ApplicationCard from "../components/ApplicationCard";
import { attachAppToDemonstration, getIDP, listApplications } from "../services/DemoAPI";

const DemoApps = () => {
    let params = useParams();
    const navigate = useNavigate();

    const [demo, setDemo] = useState()
    const [idp, setIdp] = useState()
    const [apps, setApps] = useState()
    const [appCount, setAppCount] = useState(0)
    const [privateApps, setPrivateApps] = useState()
    const [privateAppCount, setPrivateAppCount] = useState(0)
    const [communityApps, setCommunityApps] = useState()
    const [communityAppCount, setCommunityAppCount] = useState(0)
    const [error,setError] = useState(null)
    const [waiting, setWaiting] = useState(false)
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const flags = useFlags(['community_access']);

    const getDemo = useCallback(async () => {
        setError()
        if (isAuthenticated) {
            axios.get(Config.resourceServer.demoAPI +"/demonstration/"+params.demoName,
            { 
                headers: { Authorization: "Bearer " + await getAccessTokenSilently() }
            }
          )
          .then((response)=>{
            response.data.app_instances.sort((a, b) => a.label.localeCompare(b.label))
            setDemo(response.data)
        })
          .catch((error)=> { setError(error);})
        } else {
          setDemo();
        }
      },[getAccessTokenSilently, isAuthenticated,setDemo, params.demoName])

    useEffect(() => {
        async function getIDPDetails(){
            getIDP(await getAccessTokenSilently(),demo.idp_id)
            .then((response)=>{setIdp(response.data)})
            .catch((error)=> { setError(error);})
        }
        if(demo){
            getIDPDetails()
        }
    },[demo,getAccessTokenSilently])

    const getApps = useCallback(async ()=> {
        if (isAuthenticated && demo && idp) {
            listApplications(await getAccessTokenSilently(),'managed',idp.type)
            .then(response => { 
                var availableApps = response.data
                setAppCount(availableApps.length)
                for (let instanceIndex = 0; instanceIndex < demo.app_instances.length; instanceIndex++) {
                    const element = demo.app_instances[instanceIndex];
                    let index = availableApps.findIndex(x => x.application_id === element.applicationId)
                    if(index !== -1){
                        availableApps.splice(index, 1)
                    }
                }
                setApps(availableApps)
            });
            listApplications(await getAccessTokenSilently(),'private',idp.type)
            .then(response => { 
                var availableApps = response.data
                setPrivateAppCount(availableApps.length)
                for (let instanceIndex = 0; instanceIndex < demo.app_instances.length; instanceIndex++) {
                    const element = demo.app_instances[instanceIndex];
                    let index = availableApps.findIndex(x => x.application_id === element.applicationId)
                    if(index !== -1){
                        availableApps.splice(index, 1)
                    }
                }
                setPrivateApps(availableApps)
            });
            if(flags.community_access.enabled){
                listApplications(await getAccessTokenSilently(),'community',idp.type)
                .then(response => { 
                    var availableApps = response.data
                    setCommunityAppCount(availableApps.length)
                    for (let instanceIndex = 0; instanceIndex < demo.app_instances.length; instanceIndex++) {
                        const element = demo.app_instances[instanceIndex];
                        let index = availableApps.findIndex(x => x.application_id === element.applicationId)
                        if(index !== -1){
                            availableApps.splice(index, 1)
                        }
                    }
                    setCommunityApps(availableApps)
                });
            }
        }
        else {
            setApps()
            setPrivateApps()
            setCommunityApps()
        }
    },[demo, getAccessTokenSilently,isAuthenticated, flags.community_access, idp])

    useEffect(() => {
        if (isAuthenticated) {
            getDemo()
        } else {
            setDemo()
        }
    }, [getDemo, isAuthenticated, getAccessTokenSilently, setDemo])

    useEffect(() => {
        getApps()
    },[demo, getApps])

    async function attachApp(id){
        setWaiting(true)
        attachAppToDemonstration(await getAccessTokenSilently(),params.demoName, id)
        .then((response)=>{ 
            navigate('/demo/'+params.demoName)
         })
        .catch((error)=> { setError(error); setWaiting(false)})
    }

    return (
    <Container className="appComponent">
        <BreadcrumbList>
            <NavigationButton destination={`/`} msg="Demos" />
            <NavigationButton destination={`/demo/${params.demoName}`} msg={params.demoName} />
            <i href="">
            Demo Application Library
            </i>
        </BreadcrumbList>
        <Header className="contentHeader">Demo Application Library <Header.Subheader>Add application to {params.demoName}</Header.Subheader></Header>
        {error ? (
            <ErrorMessage error={error} retryAction={getApps}/>
        ):null}
        
        <Header as="h3">
            Managed applications
            {apps && <span className="appCount">({apps.length}/{appCount})</span>}
            <Header.Subheader>Applications created and run by Demo Engineering.</Header.Subheader>
        </Header>
        {apps ? (   
            <Container>
                {apps.length !== 0 ? (  
                        <Card.Group>
                        {apps.map((app) =>
                            <ApplicationCard application={app} waiting={waiting} attachEvent={attachApp}/>
                        )}
                        </Card.Group> 
                ) : (
                    <Container>
                       Could not find any managed applications not already attached.
                    </Container>
                )}
            </Container>
        ) : (
            <Loader active inline />
        )}

        <Divider/>

        <Header as="h3">
            Your applications
            {privateApps && <span className="appCount">({privateApps.length}/{privateAppCount})</span>}
            <Header.Subheader>Custom demonstration applications you have created.</Header.Subheader>
        </Header>
        { privateApps ? (
            <Container>
                {privateApps.length !== 0 ? (  
                        <Card.Group>
                        {privateApps.map((app) =>
                            <ApplicationCard application={app} waiting={waiting} attachEvent={attachApp}/>
                        )}
                        </Card.Group> 
                ) : (
                    <Container>
                       {privateAppCount > 0 ? "Could not find any of your custom applications not already attached." : "You do not have any custom applications yet."}
                    </Container>
                )}
            </Container>
        ) : (
            <Loader active inline />
        )}

        {flags.community_access.enabled &&   
            <Container>   
                <Divider/>
                <Header as="h3">
                    Community Applications
                    {communityApps && <span className="appCount">({communityApps.length}/{communityAppCount})</span>}
                    <Header.Subheader>Demonstrations created by the Okta SE community.</Header.Subheader>
                </Header>

                {communityApps ? (
                <Container>
                    {communityApps.length !== 0 ? (  
                        <Card.Group>
                    {communityApps.map((app) =>
                        <ApplicationCard application={app} waiting={waiting} attachEvent={attachApp}/>
                    )}
                    </Card.Group> 
                
                ) : (
                    <Container>
                        Could not find any community applications not already attached.
                    </Container> 
                )}
                </Container>
                ) : (
                <Loader active inline />
                )}
            </Container>
        }
    </Container>
    )
}
export default DemoApps